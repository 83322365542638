import React from "react";
import Login from "./pages/login";

function App() {
  return (
        <Login />
  );
}

export default App;
